import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";

const Login = ({ onLogin, loginState }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const resetError = () => setError("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const response = await fetch("/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.message === "MFA required.") {
          onLogin(true); // Notify parent MFA is required
        } else {
          onLogin(false, data.user); // Notify parent of successful login
          navigate("/dashboard");
        }
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Login failed. Please try again.");
      }
    } catch (err) {
      console.error("Error during login:", err);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const otpValue = otp.join("");
      const response = await fetch("/auth/login/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ otp: otpValue }),
      });

      if (response.ok) {
        const data = await response.json();
        onLogin(false, data.user); // Notify parent of successful login
        navigate("/dashboard");
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Invalid OTP. Please try again.");
      }
    } catch (err) {
      console.error("Error verifying OTP:", err);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(-1);
    setOtp(newOtp);
    resetError();

    if (value && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xs">
      <Box sx={{ mt: 4, mb: 2, textAlign: "center" }}>
        <Typography variant="h4">
          {loginState === "mfaRequired" ? "MFA Verification" : "Login"}
        </Typography>
        {loginState === "mfaRequired" && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Enter the OTP sent to your email to continue.
          </Typography>
        )}
      </Box>
      {error && (
        <Typography color="error" variant="body2" sx={{ textAlign: "center", mb: 2 }}>
          {error}
        </Typography>
      )}
      {loginState === "mfaRequired" ? (
        <form onSubmit={handleVerifyOTP}>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1, mb: 2 }}>
            {otp.map((digit, index) => (
              <TextField
                key={index}
                id={`otp-${index}`}
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                required
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center" },
                  type: "tel",
                  autoComplete: "one-time-code",
                }}
              />
            ))}
          </Box>
          <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
            Verify
          </Button>
        </form>
      ) : (
        <form onSubmit={handleLogin}>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                resetError();
              }}
              required
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                resetError();
              }}
              required
            />
          </Box>
          <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
            Login
          </Button>
        </form>
      )}
    </Container>
  );
};

export default Login;