import React from 'react';
import { Box, Button } from '@mui/material';

const CalendarToolbar = ({ calendarView, setCalendarView, categories, activeCategories, toggleCategory }) => {
    return (
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* View Toggles */}
            <Box>
                <Button
                    variant={calendarView === 'dayGridMonth' ? 'contained' : 'outlined'}
                    onClick={() => setCalendarView('dayGridMonth')}
                    sx={{ m: 1 }}
                >
                    Month
                </Button>
                <Button
                    variant={calendarView === 'timeGridWeek' ? 'contained' : 'outlined'}
                    onClick={() => setCalendarView('timeGridWeek')}
                    sx={{ m: 1 }}
                >
                    Week
                </Button>
                <Button
                    variant={calendarView === 'timeGridDay' ? 'contained' : 'outlined'}
                    onClick={() => setCalendarView('timeGridDay')}
                    sx={{ m: 1 }}
                >
                    Day
                </Button>
            </Box>

            {/* Category Filters */}
            <Box>
                {categories.map((category) => (
                    <Button
                        key={category}
                        variant={activeCategories.includes(category) ? 'contained' : 'outlined'}
                        onClick={() => toggleCategory(category)}
                        sx={{ m: 1 }}
                    >
                        {category}
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export default CalendarToolbar;