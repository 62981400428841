import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { categoryColors } from './DataHelper';

const Calendar = ({ calendarRef, calendarView, filteredEvents, handleDatesSet, setSelectedEvent }) => {
    return (
        <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView={calendarView}
            events={filteredEvents}
            eventClick={(info) => setSelectedEvent(info.event)}
            datesSet={handleDatesSet}
            eventContent={(arg) => (
                <div
                    style={{
                        backgroundColor: categoryColors[arg.event.extendedProps.type] || '#9e9e9e',
                        color: '#fff',
                        padding: '4px',
                        borderRadius: '4px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {arg.event.title}
                </div>
            )}
        />
    );
};

export default Calendar;