import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Grid2,
    Card,
    CardContent,
    CircularProgress,
    ToggleButton,
    ToggleButtonGroup,
    Select,
    MenuItem,
} from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalSteps: 0,
        activityCount: 0,
    });
    const [weightData, setWeightData] = useState([]);
    const [isSyncing, setIsSyncing] = useState(false);
    const [range, setRange] = useState('week'); // Default to 'week'
    const [secondaryMetric, setSecondaryMetric] = useState('bmi'); // Default secondary metric

    const metrics = {
        bmi: 'BMI',
        bodyFat: 'Body Fat %',
        bodyWater: 'Body Water %',
        boneMass: 'Bone Mass (kg)',
        muscleMass: 'Muscle Mass (kg)',
    };

    const generateDateRange = (startDate, endDate) => {
        const dates = [];
        let current = new Date(startDate);
        const end = new Date(endDate);

        while (current <= end) {
            dates.push(current.toISOString().split('T')[0]);
            current.setDate(current.getDate() + 1);
        }

        return dates;
    };

    const getDateRange = () => {
        const today = new Date();
        if (range === 'yesterday') {
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            const date = yesterday.toISOString().split('T')[0] + 'T00:00:00Z';
            const endDate = yesterday.toISOString().split('T')[0] + 'T23:59:00Z';
            return { startDate: date, endDate: endDate };
        } else if (range === 'week') {
            const weekStart = new Date(today);
            const dayOfWeek = weekStart.getDay(); // Sunday = 0, Monday = 1, ...
            const diff = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Calculate difference to Monday
            weekStart.setDate(today.getDate() - diff);
            const startDate = weekStart.toISOString().split('T')[0] + 'T00:00:00Z';
            const endDate = today.toISOString().split('T')[0] + 'T23:59:00Z';
            return { startDate, endDate };
        } else if (range === 'month') {
            const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
            const startDate = monthStart.toISOString().split('T')[0] + 'T00:00:00Z';
            const endDate = today.toISOString().split('T')[0] + 'T23:59:00Z';
            return { startDate, endDate };
        }
    };

    const fetchDashboardData = async () => {
        try {
            const { startDate, endDate } = getDateRange();

            const [stepsRes, activitiesRes, weightRes] = await Promise.all([
                fetch(`/data/date/steps?startDate=${startDate}&endDate=${endDate}`),
                fetch(`/data/date/activities?startDate=${startDate}&endDate=${endDate}`),
                fetch(`/data/date/weight?startDate=${startDate}&endDate=${endDate}`),
            ]);

            if (stepsRes.ok && activitiesRes.ok && weightRes.ok) {
                const [steps, activities, weight] = await Promise.all([
                    stepsRes.json(),
                    activitiesRes.json(),
                    weightRes.json(),
                ]);

                const stats = {
                    totalSteps: steps.reduce((sum, s) => sum + s.count, 0),
                    activityCount: activities.length,
                };

                setStats(stats);

                const dates = generateDateRange(startDate.split('T')[0], endDate.split('T')[0]);
                const dataByDate = dates.map((date) => {
                    const entry = weight.find((w) => w.date === date);
                    return {
                        date,
                        weight: entry?.weight || null,
                        [secondaryMetric]: entry?.[secondaryMetric] || null,
                    };
                });

                setWeightData(dataByDate);
            } else {
                console.error('Failed to fetch one or more datasets');
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    };

    const handleGarminSync = async () => {
        setIsSyncing(true);
        try {
            const response = await fetch('/data/garmin/sync', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.ok) {
                alert('Garmin data synced successfully!');
                fetchDashboardData();
            } else {
                alert('Failed to sync Garmin data.');
            }
        } catch (error) {
            console.error('Error syncing Garmin data:', error);
            alert('An error occurred while syncing Garmin data.');
        } finally {
            setIsSyncing(false);
        }
    };

    useEffect(() => {
        fetchDashboardData();
    }, [range, secondaryMetric]);

    const weightChartData = {
        labels: weightData.map((entry) => entry.date),
        datasets: [
            {
                label: 'Weight (kg)',
                data: weightData.map((entry) => entry.weight),
                borderColor: 'blue',
                fill: false,
                spanGaps: true, // Avoid connecting gaps
                pointRadius: weightData.map((entry) => (entry.weight ? 5 : 0)), // Only show markers where data exists
            },
            {
                label: metrics[secondaryMetric],
                data: weightData.map((entry) => entry[secondaryMetric]),
                borderColor: 'green',
                fill: false,
                spanGaps: true,
                yAxisID: 'secondary-y',
                pointRadius: weightData.map((entry) => (entry[secondaryMetric] ? 5 : 0)),
            },
        ],
    };

    const weightChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: { display: true, text: 'Date' },
                type: 'category',
            },
            y: {
                title: { display: true, text: 'Weight (kg)' },
            },
            'secondary-y': {
                position: 'right',
                title: { display: true, text: metrics[secondaryMetric] },
                grid: { drawOnChartArea: false },
            },
        },
    };

    const renderStatsCard = (title, value) => {
        if (value === undefined || value === null) return null;
        return (
            <Grid2 item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">{title}</Typography>
                        <Typography variant="h4">
                            {typeof value === 'number' ? value.toLocaleString() : value}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid2>
        );
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Dashboard
            </Typography>
            <Typography variant="body1">Welcome to your dashboard!</Typography>

            {/* Sync Garmin Data */}
            <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleGarminSync}
                    disabled={isSyncing}
                >
                    {isSyncing ? <CircularProgress size={24} /> : 'Sync Garmin Data'}
                </Button>
            </Box>

            {/* Toggle for Date Range */}
            <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                    value={range}
                    exclusive
                    onChange={(event, newRange) => {
                        if (newRange) setRange(newRange);
                    }}
                    aria-label="Date range toggle"
                >
                    <ToggleButton value="yesterday" aria-label="Yesterday">
                        Yesterday
                    </ToggleButton>
                    <ToggleButton value="week" aria-label="This Week">
                        This Week
                    </ToggleButton>
                    <ToggleButton value="month" aria-label="This Month">
                        This Month
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {/* Stats Cards */}
            <Grid2 container spacing={3} sx={{ mt: 3 }}>
                {renderStatsCard('Total Steps', stats.totalSteps)}
                {renderStatsCard('Activity Count', stats.activityCount)}
            </Grid2>

            {/* Secondary Metric Selector */}
            <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}>
                <Select
                    value={secondaryMetric}
                    onChange={(e) => setSecondaryMetric(e.target.value)}
                    displayEmpty
                >
                    {Object.keys(metrics).map((metric) => (
                        <MenuItem key={metric} value={metric}>
                            {metrics[metric]}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            {/* Weight Chart */}
            {weightData.length > 0 && (
                <Box sx={{ mt: 4, height: 400 }}>
                    <Typography variant="h6" gutterBottom>
                        Weight Trend
                    </Typography>
                    <Line data={weightChartData} options={weightChartOptions} />
                </Box>
            )}
        </Container>
    );
};

export default Dashboard;