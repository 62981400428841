export const secondsToHms = (seconds) => {
    if (typeof seconds !== 'number' || isNaN(seconds) || seconds <= 0) return '00:00:00';
    const roundedSeconds = Math.floor(seconds);
    const hours = Math.floor(roundedSeconds / 3600);
    const minutes = Math.floor((roundedSeconds % 3600) / 60);
    const secs = roundedSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs)}`;
};

export const categoryColors = {
    Walking: '#4caf50',
    Weight: '#2196f3',
    Steps: '#ff9800',
    Cycling: '#9c27b0',
    Running: '#e91e63',
    Swimming: '#00bcd4',
    Gym: '#ff5722',
    'Heart Rate': '#f44336',
};