import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Box,
  Slide,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const EventModal = ({ event, open, onClose }) => {
  if (!event) return null; // Ensure no rendering if event is not passed

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="event-dialog-title"
      aria-describedby="event-dialog-description"
    >
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          {event.title}
        </Typography>
        <Box>
          {event.extendedProps?.distance && (
            <Typography>
              Distance: {event.extendedProps.distance}
            </Typography>
          )}
          {event.extendedProps?.calories && (
            <Typography>Calories: {event.extendedProps.calories}</Typography>
          )}
          {event.extendedProps?.duration && (
            <Typography>Duration: {event.extendedProps.duration}</Typography>
          )}
          {event.extendedProps?.weight && (
            <Typography>Weight: {event.extendedProps.weight}</Typography>
          )}
          {event.extendedProps?.bmi && (
            <Typography>BMI: {event.extendedProps.bmi}</Typography>
          )}
          {event.extendedProps?.bodyFat && (
            <Typography>Body Fat: {event.extendedProps.bodyFat}</Typography>
          )}
          {event.extendedProps?.bodyWater && (
            <Typography>Body Water: {event.extendedProps.bodyWater}</Typography>
          )}
          {event.extendedProps?.boneMass && (
            <Typography>Bone Mass: {event.extendedProps.boneMass}</Typography>
          )}
          {event.extendedProps?.muscleMass && (
            <Typography>Muscle Mass: {event.extendedProps.muscleMass}</Typography>
          )}
          {event.extendedProps?.count && (
            <Typography>Steps: {event.extendedProps.count}</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventModal;