import React, { useState, useEffect, useRef } from 'react';
import { Box, Container } from '@mui/material';
import CalendarToolbar from '../components/CalendarToolbar';
import Calendar from '../components/Calendar';
import EventModal from '../components/EventModal';
import { secondsToHms } from '../components/DataHelper';

const DataPage = () => {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activeCategories, setActiveCategories] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [calendarView, setCalendarView] = useState('dayGridMonth');
    const calendarRef = useRef(null);

    // Fetch calendar data based on the start and end dates
    const fetchCalendarData = async (startDate, endDate) => {
        try {
            const [activitiesRes, heartRateRes, weightRes, stepsRes] = await Promise.all([
                fetch(`/data/date/activities?startDate=${startDate}&endDate=${endDate}`),
                fetch(`/data/date/heart-rate?startDate=${startDate}&endDate=${endDate}`),
                fetch(`/data/date/weight?startDate=${startDate}&endDate=${endDate}`),
                fetch(`/data/date/steps?startDate=${startDate}&endDate=${endDate}`),
            ]);

            if (activitiesRes.ok && heartRateRes.ok && weightRes.ok && stepsRes.ok) {
                const activities = await activitiesRes.json();
                const heartRate = await heartRateRes.json();
                const weight = await weightRes.json();
                const steps = await stepsRes.json();

                const formattedActivities = activities.map((activity) => ({
                    title: activity.category,
                    start: activity.start,
                    extendedProps: {
                        type: activity.category,
                        name: activity.title,
                        distance: activity.extendedProps.distance
                            ? `${activity.extendedProps.distance} miles`
                            : null,
                        calories: activity.extendedProps.calories,
                        duration: secondsToHms(activity.extendedProps.duration || 0),
                    },
                }));

                const formattedHeartRate = heartRate.map((hr) => ({
                    title: 'Heart Rate',
                    start: hr.date,
                    extendedProps: {
                        type: 'Heart Rate',
                        average: hr.average,
                        max: hr.max,
                    },
                }));

                const formattedWeight = weight.map((weightData) => ({
                    title: 'Weight',
                    start: weightData.date,
                    extendedProps: {
                        type: 'Weight',
                        weight: weightData.weight ? `${weightData.weight} kg` : null,
                        bmi: weightData.bmi ? `${parseFloat(weightData.bmi).toFixed(2)}` : null,
                        bodyFat: weightData.bodyFat ? `${weightData.bodyFat}%` : null,
                        bodyWater: weightData.bodyWater ? `${weightData.bodyWater}%` : null,
                        boneMass: weightData.boneMass ? `${weightData.boneMass} kg` : null,
                        muscleMass: weightData.muscleMass ? `${weightData.muscleMass} kg` : null,
                    },
                }));

                const formattedSteps = steps.map((stepData) => ({
                    title: 'Steps',
                    start: stepData.date,
                    extendedProps: {
                        type: 'Steps',
                        count: stepData.count.toLocaleString(), // Format steps count
                    },
                }));

                const combinedEvents = [
                    ...formattedActivities,
                    ...formattedHeartRate,
                    ...formattedWeight,
                    ...formattedSteps,
                ];

                const deduplicatedEvents = Array.from(
                    new Map(
                        combinedEvents.map((event) => [`${event.title}-${event.start}`, event])
                    ).values()
                );

                setCalendarEvents(deduplicatedEvents);

                const allCategories = [...new Set(deduplicatedEvents.map((event) => event.extendedProps.type))];
                setCategories(allCategories);
                setActiveCategories(allCategories);
            } else {
                console.error('Failed to fetch one or more calendar datasets');
            }
        } catch (error) {
            console.error('Error fetching calendar data:', error);
        }
    };

    // Handle date range change from FullCalendar
    const handleDatesSet = (info) => {
        const startDate = info.startStr;
        const endDate = info.endStr;
        fetchCalendarData(startDate, endDate);
    };

    // Toggle category visibility
    const toggleCategory = (category) => {
        setActiveCategories((prev) =>
            prev.includes(category) ? prev.filter((cat) => cat !== category) : [...prev, category]
        );
    };

    // Filter events based on active categories
    useEffect(() => {
        setFilteredEvents(
            calendarEvents.filter((event) => activeCategories.includes(event.extendedProps.type))
        );
    }, [activeCategories, calendarEvents]);

    // Fetch data on initial render
    useEffect(() => {
        const today = new Date();
        const startDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString();
        const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString();
        fetchCalendarData(startDate, endDate);
    }, []);

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', py: 5 }}>
            <Container maxWidth="lg">
                {/* Toolbar for toggles and category filters */}
                <CalendarToolbar
                    calendarView={calendarView}
                    setCalendarView={(view) => {
                        setCalendarView(view);
                        if (calendarRef.current) {
                            calendarRef.current.getApi().changeView(view);
                        }
                    }}
                    categories={categories}
                    activeCategories={activeCategories}
                    toggleCategory={toggleCategory}
                />

                {/* Calendar */}
                <Box
                    sx={{
                        mb: 3,
                        p: 2,
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Calendar
                        calendarRef={calendarRef}
                        calendarView={calendarView}
                        filteredEvents={filteredEvents}
                        handleDatesSet={handleDatesSet}
                        setSelectedEvent={setSelectedEvent}
                    />
                </Box>

                {/* Event details modal */}
                <EventModal
                    event={selectedEvent}
                    open={!!selectedEvent}
                    onClose={() => setSelectedEvent(null)}
                />
            </Container>
        </Box>
    );
};

export default DataPage;